<template>
  <div>
    <el-card>
      <el-form
        ref="formref"
        label-width="150px"
        :model="form"
        :rules="addFormRulue"
      >
        <el-form-item label="活动分类" prop="acId">
          <el-select v-model="form.acId" placeholder="请选择" @change="map_if">
            <el-option
              v-for="(item, index) in huodong"
              :key="index"
              :label="item"
              :value="index * 1"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="报名渠道">
          <el-select v-model="channel" placeholder="请选择">
            <el-option label="本站" value="1" />
            <el-option label="活动行" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="活动标题" prop="title">
          <el-col :span="8">
            <el-input v-model="form.title" :maxlength="200" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="主办单位" prop="organizers">
          <el-col :span="8">
            <el-input
              v-model="form.organizers"
              maxlength="100"
              show-word-limit
            />
          </el-col>
        </el-form-item>
        <el-form-item label="举办活动时间" prop="time">
          <el-col :span="8">
            <el-date-picker
              v-model="form.time"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              @change="sd"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="报名时间" prop="timeSigningUp">
          <el-col :span="8">
            <el-date-picker
              v-model="form.timeSigningUp"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              @change="sd"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="限制人数">
          <el-input-number v-model="form.people" label="请输入数字" :min="0" />
          <span class="waring ml">0为不限人数</span>
        </el-form-item>

        <el-form-item label="关联企业" prop="enterpriseIds">
          <el-select
            v-model="form.enterpriseIds"
            multiple
            filterable
            remote
            :reserve-keyword="false"
            placeholder="请输入企业名称模糊查询"
            :remote-method="remoteMethod"
            @change="emptyT"
            :loading="loading"
            style="width: 480px;"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="演讲嘉宾" prop="speakerIds">
          <el-select
            v-model="form.speakerIds"
            multiple
            filterable
            remote
            placeholder="请输入嘉宾名称模糊查询"
            :remote-method="remoteMethod2"
            :loading="loading"
            style="width: 480px;"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.realName || item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="活动行报名链接"
          v-show="channel == 2"
          prop="regUrl"
        >
          <el-col :span="8">
            <el-input v-model="form.regUrl" placeholder="例如：活动行链接">
              <!-- <template slot="prepend">http://</template> -->
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="独立活动页链接">
          <el-col :span="8">
            <el-input
              v-model="form.otherUrl"
              placeholder="填写后将跳过活动详情页，直接打开活动链接"
            >
              <!-- <template slot="prepend">http://</template> -->
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="线上活动链接" ref="address_b3" class="map_if">
          <el-col :span="8">
            <el-input v-model="form.onlineUrl" placeholder="">
              <!-- <template slot="prepend">http://</template> -->
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="活动地址" prop="district" ref="address_b1">
          <!-- <el-select v-model="form.country" placeholder="请选择" style="width: 100px; margin-right: 12px">
            <el-option label="中国" value="000086" />
          </el-select> -->
          <el-col :span="2">
            <el-select
              v-model="form.province"
              placeholder="请选择"
              @change="ci"
            >
              <el-option
                v-for="item in province"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-col>
          <el-col :span="2" class="ml">
            <el-select v-model="form.city" placeholder="请选择" @change="cs">
              <el-option
                v-for="item in city"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-col>
          <el-col :span="2" class="ml">
            <el-select v-model="form.district" @change="$forceUpdate()">
              <el-option
                v-for="item in district"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-col>
          <el-col
            :span="7"
            class="ml"
            style="margin-left: -20px; padding-right: 30px;"
          >
            <el-autocomplete
              v-model="form.detailAddress"
              style="width: 100%"
              class="in"
              placeholder="请输入详细地址"
              :fetch-suggestions="querySearch"
              @input="getAddress"
              @select="mySelect"
            />
          </el-col>

          <el-col :span="2" class="ml">
            <el-input
              v-model="form.location.lat"
              disabled
              placeholder="坐标x"
            />
          </el-col>
          <el-col :span="2" class="ml">
            <el-input
              v-model="form.location.lng"
              disabled
              placeholder="坐标y"
            />
          </el-col>
        </el-form-item>

        <el-form-item label="地图" ref="address_b2">
          <Map
            style="width: 800px;"
            @position="getMsgFormSon"
            v-bind:addme="form.district"
          ></Map>
        </el-form-item>
        <el-form-item label="活动海报" prop="banner">
          <up-img
            :action="action"
            :banner="form.banner"
            @event="setImg"
          ></up-img>
        </el-form-item>

        <el-form-item label="活动描述" prop="desc">
          <el-col :span="10">
            <el-input
              v-model="form.desc"
              maxlength="400"
              show-word-limit
              type="textarea"
              :rows="6"
            />
          </el-col>
        </el-form-item>

        <el-form-item label="活动详情" prop="content">
          <!-- <quill-editor ref="myQuillEditor" v-model="form.content" /> -->
          <Tiny :value="form.content" @input="chang"></Tiny>
        </el-form-item>
        <el-form-item label="报名成功自定义文案" prop="" v-show="channel == 1">
          <el-col :span="10">
            <el-input v-model="form.applySuccessMessage" />
          </el-col>
        </el-form-item>
        <el-form-item label="邀请函模板" prop="" v-show="channel == 1">
          <up-img
            :action="templates"
            :banner="form.invitationTemplate"
            @event="setPosters"
            :tip_text="false"
            theText="图片尺寸 1080*640，.jpg 或 .png格式，不超过 4M"
          ></up-img>
        </el-form-item>
        <el-form-item
          label="邀请函摸版名字位置"
          prop="invitationTemplateType"
          v-show="channel == 1"
        >
          <el-select v-model="form.invitationTemplateType" placeholder="请选择">
            <el-option label="靠左" :value="0" />
            <el-option label="居中" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="票种信息"
          prop="timeSigningUp"
          v-show="channel == 1"
        >
          <el-table
            :data="ticketList"
            style="width: 100%"
            border
            stripe
            :header-cell-style="rowClass"
          >
            <el-table-column prop="name" label="票种名称"> </el-table-column>
            <el-table-column prop="type" label="套票设置">
              <template slot-scope="{ row }">
                <p>{{ row.type == 0 ? '免费票' : '' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格">
              <template slot-scope="{ row }">
                <!-- {{row.price?:row.price:'免费'}} -->
                {{ row.price || '免费' }}
              </template>
            </el-table-column>
            <el-table-column prop="balancePrice" label="人均票价" />
            <el-table-column prop="number" label="数量" />
            <el-table-column prop="needCheck" label="审核设置">
              <template slot-scope="{ row }">
                <el-select v-model="row.needCheck" placeholder="请选择套票">
                  <el-option label="需要审核" :value="0"></el-option>
                  <el-option label="无需审核" :value="1"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row, $index }">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
                  class="skyblue"
                  @click="editTicket(row, $index)"
                  v-show="
                    $store.state.btn_if.indexOf('activity_list_edit') != -1
                  "
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="mini"
                  class="red"
                  @click="delTicket(row, $index)"
                  v-show="
                    $store.state.btn_if.indexOf('activity_list_delete') != -1
                  "
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px">
            <el-button @click="newTicket()">新增票种</el-button>
          </div>
        </el-form-item>

        <el-form-item label="报名表单" v-show="channel == 1">
          <new-form ref="newForm"></new-form>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="id == null"
            size="medium"
            type="primary"
            style="margin-top: 50px"
            @click="add"
            >{{ btn ? '提交' : 'loading..' }}</el-button
          >
          <el-button
            v-if="id"
            size="medium"
            type="primary"
            style="margin-top: 50px"
            @click="addedit"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <Drawers
      ref="drawer"
      :title="title"
      @getlist="createATicket"
      @edit="edticket"
      @getTicket="getTicket"
    ></Drawers>
  </div>
</template>

<script>
import $ from 'jquery'
// import { quillEditor } from 'vue-quill-editor'
// import quillConfig from '../../quill'
import { Quill } from 'vue-quill-editor'
import { ImageExtend } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
// import Wang from '../../components/wang.vue'
import upImg from '../../components/upImg'
// import tiny from '../../components/Tinymce'
import Map from '../../components/welcome.vue'
import mixins from '@/mixin/list'

export default {
  mixins: [mixins],

  // watch: {
  //   'form.time'(newValue, oldValue) {
  //     console.log(newValue, oldValue)
  //   },
  // },
  data() {
    return {
      queryData: [],
      btn: true,
      //   quillOption: quillConfig,
      action: {
        action: 'activity'
      },
      templates: {
        action: 'template'
      },
      list: '',
      channel: '1',
      time: [],
      ticketList: [],
      addFormRulue: {
        title: [{ required: true, message: '请输入活动标题', trigger: 'blur' }],
        time: [{ required: true, message: '请输入活动时间', trigger: 'blur' }],
        banner: [
          { required: true, message: '请上传活动海报', trigger: 'blur' }
        ],
        district: [
          { required: true, message: '请输入活动地址', trigger: 'blur' }
        ],
        desc: [{ required: true, message: '请输入活动描述', trigger: 'blur' }],
        regUrl: [
          { required: true, message: '请输入活动行报名链接', trigger: 'blur' }
        ],
        organizers: [
          { required: true, message: '请输入主办单位', trigger: 'blur' }
        ],
        acId: [{ required: true, message: '请选择活动分类', trigger: 'blur' }],
        timeSigningUp: [
          { required: true, message: '请选择报名时间', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入活动详情', trigger: 'blur' }
        ]
      },
      // people:0,
      form: {
        title: '',
        beginTime: '',
        endTime: '',
        city: '',
        detailAddress: '',
        organizers: '',
        acId: 3,
        time: [],
        people: 0,
        content: '',
        province: '',
        regUrl: '',
        otherUrl: '',
        orderNum: 0,
        desc: '',
        district: '',
        top: 0,
        recommend: 0,
        location: '',
        initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
        timeSigningUp: [],
        applySuccessMessage: '',
        invitationTemplate: '',
        enterpriseIds: [],
        speakerIds: [],
        invitationTemplateType: 0,
        onlineUrl: ''
        // viewsRule: '10,100'
      },
      huodong: [],
      province: [],
      city: [],
      district: [],

      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      id: null,
      header: {
        Token: sessionStorage.getItem('token')
      },
      options: [],
      options2: [],
      loading: false,
      title: '添加票种'
    }
  },

  created() {
    this.getlist()
    this.getadd()
    this.getp()
    sessionStorage.setItem('action', 'activityEditor')
    this.bus.$on('new', () => {
      this.form = {
        title: '',
        beginTime: '',
        endTime: '',
        city: '',
        detailAddress: '',
        organizers: '',
        acId: 3,
        time: [],
        people: 0,
        content: '',
        province: '',
        regUrl: '',
        otherUrl: '',
        orderNum: 0,
        desc: '',
        district: '',
        top: 0,
        recommend: 0,
        location: '',
        initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
        viewsRule: '10,100'
      }
      this.id = null
    })
  },

  updated() {
    // this.getc()
    // console.log(this.form.time)
    // console.log(this.form.content)
  },
  mounted() {
    // 监听页面滚动事件
    // console.log(this.form.city);
    // 注意：如果由于自己的vue中的样式导致监听不到，可以尝试监听body或者'#app-root'的滚动事件
    // console.log(this.form.city);
  },

  methods: {
    emptyT(val, val1) {
      console.log(val, val1)
    },
    setImg(val) {
      // console.log(val
      this.form.banner = val
      this.$forceUpdate()
    },
    editTicket(val, i) {
      // console.log(val,i);
      this.$refs.drawer.interface = 3
      this.$refs.drawer.drawer = true
      this.$refs.drawer.ruleForm = { ...val }
      this.$refs.drawer.editId = i
      this.title = '编辑票种'
    },
    edticket(val1, val2) {
      this.ticketList[val2] = val1
      this.ticketList = [...this.ticketList]
    },
    createATicket(val) {
      // this.ticketList.push
      console.log(val);
      this.ticketList.push(val)
      // this.ticketList=[...this.ticketList]
    },
    newTicket() {
      this.$refs.drawer.editId = null
      this.$refs.drawer.interface = 3
      this.$refs.drawer.drawer = true
      this.$refs.drawer.activityId = this.$route.query.id
      this.title = '添加票种'
    },
    async delTicket(row, val) {
      if (row.id) {
        var { data: res } = await this.$http.post('/admin/Activity/ticketDel', {
          id: row.id
        })
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.getTicket()
        } else {
          this.$message.error(res.message)
        }
        return
      }
      this.ticketList.splice(val, 1)
      this.ticketList = [...this.ticketList]
    },
    setPosters(val) {
      this.form.invitationTemplate = val
      this.$forceUpdate()
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get(
            `/admin/Enterprise/getList?page=1&pageSize=50&name=${qu}&isHide=&type=&business=&serverArea=&createDate=&people=&financing=&top=`
          )
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              // console.log(res.data)
              // this.options = []
              // res.data.list.forEach(item => {
              //   this.options.push({ value: item.id, label: item.name })
              // })
              this.options = res.data.list
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    remoteMethod2(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get(
            `https://alpha.yfchuhai.com/admin/User/getList?page=1&pageSize=50&keyword=${qu}&order=&type=&status=&waId=&showCircles=&certType=&business=&ugId=&regDate=`
          )
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              // console.log(res.data)
              // this.options = []
              // res.data.list.forEach(item => {
              //   this.options.push({ value: item.id, label: item.name })
              // })
              this.options2 = res.data.list
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    map_if() {
      if (this.form.acId == 2) {
        this.$refs['address_b1'].$el.classList.add('map_if')
        this.$refs['address_b2'].$el.classList.add('map_if')
        this.$refs['address_b3'].$el.classList.remove('map_if')
        // console.log()
      } else {
        this.$refs['address_b2'].$el.classList.remove('map_if')
        this.$refs['address_b1'].$el.classList.remove('map_if')
        this.$refs['address_b3'].$el.classList.add('map_if')
      }
    },
    chang(val) {
      this.form.content = val
    },
    querySearch(queryString, cb) {
      cb(this.queryData)
      // console.log(queryString);
    },

    async getAddress() {
      let getO = JSON.parse(sessionStorage.getItem('center'))
      // console.log(getO);
      let url = `https://apis.map.qq.com/ws/place/v1/search?boundary=nearby(${getO.lat},${getO.lng},1000)&page_size=5&page_index=1&keyword=${this.form.detailAddress}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`
      const res = await this.$jsonp(url, {
        output: 'jsonp'
      })
      console.log(res)
      this.queryData = []
      // [...res.data, { value: res.data.title }, { address: res.data.address }]
      if (!!res.data) {
        res.data.forEach(item => {
          this.queryData.push({
            value: item.title,
            address: item.address,
            point: item.location
          })
        })
        // console.log(this.queryData);
      }
    },

    getMsgFormSon(e) {
      this.form.location = e
      // .form.location = e.lat + ',' + e.lng
      console.log(this.form.location.lat)
    },
    onEditorFocus() {
      // console.log($('.ql-toolbar'));
      $('.ql-toolbar').css({
        'background-color': 'white',
        position: 'fixed',
        top: '0',
        left: '0',
        'z-index': '999',
        width: '100%'
      })
    },
    onEditorBlur() {
      $('.ql-toolbar').css({
        'background-color': 'white',
        position: 'static',
        top: '0',
        left: '0',
        width: '100%'
      })
    },
    sd() {
      this.$forceUpdate()
    },
    getlist() {
      // console.log(this.$route.query.id);
      this.id = this.$route.query.id
      if (this.$route.query.id) {
        this.$http
          .get('/admin/Activity/getById?id=' + this.$route.query.id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log('返回')
              this.form = {
                ...res.data,
                time: [res.data.beginTime, res.data.endTime],
                location: {
                  lat: res.data.location.split(',')[0],
                  lng: res.data.location.split(',')[1]
                },
                timeSigningUp: [res.data.applyBeginTime, res.data.applyEndTime],
                enterpriseIds: res.data.enterpriseIds.split(','),
                speakerIds: res.data.speakerIds.split(',')
                // initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50
              }
              if (this.form.regUrl) {
                this.channel = '2'
              }
              // sessionStorage.setItem('city', this.form.city)
              sessionStorage.setItem(
                'center',
                JSON.stringify(this.form.location)
              ),
                this.form.enterpriseIds.forEach((item, i) => {
                  this.options.push({
                    id: item,
                    name: this.form.enterprisesName.split(',')[i]
                  })
                })
              this.form.speakerIds.forEach((item, i) => {
                this.options2.push({
                  id: item,
                  realName: this.form.speakersName.split(',')[i]
                })
              })
              if (this.form.forms) {
                this.$refs.newForm.configFormData.list = JSON.parse(
                  this.form.forms
                )
              }

              this.getc()
              this.getqu()
              this.map_if()
              // console.log(this.form.city);
            } else {
              this.$message.error(res.message)
            }
          })
        this.getTicket()
      }
    },
    getadd() {
      this.$http
        .get('/admin/ActivityCategory/getList')
        .then(({ data: res }) => {
          console.log(res)
          this.huodong = res.data
        })
    },
    getTicket() {
      this.$http
        .get('/admin/Activity/getTicketList', {
          params: {
            activityId: this.id,
            page: 1,
            pageSize: 5000
          }
        })
        .then(({ data: res }) => {
          this.ticketList = res.data.list
        })
    },
    hs(i) {
      if (i > 2) {
        var str = `
           <li><span style="display: inline-block; width: 80px;">${this.tg[i].txt}:</span> <input type="text" name="" id=""></li>
            `
        $(this.$refs.ads).append(str)
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        console.log(res.data.url)
        this.form.banner = res.data.url
        this.$forceUpdate()
        console.log(file)
      } else {
        this.$message.error(res.message)
      }
      // this.form.banner = URL.createObjectURL(file.raw)
    },

    add() {
      const form = {
        ...this.form,
        regUrl: this.form.regUrl,
        otherUrl: this.form.otherUrl,
        beginTime: this.form.time[0],
        endTime: this.form.time[1],
        location: !!this.form.location.lat
          ? this.form.location.lat + ',' + this.form.location.lng
          : '',
        applyBeginTime: this.form.timeSigningUp && this.form.timeSigningUp[0],
        applyEndTime: this.form.timeSigningUp && this.form.timeSigningUp[1],
        ticketJson: JSON.stringify(this.ticketList),
        formsJson: JSON.stringify(this.$refs.newForm.configFormData.list),
        enterpriseIds: this.form.enterpriseIds.toString(),
        speakerIds: this.form.speakerIds.toString()
      }
      if (this.btn) {
        this.$refs.formref.validate(val => {
          if (this.channel == 2 && !this.form.regUrl) {
            this.$message.error('请输入活动行报名链接')
            return
          }
          this.$http.post('/admin/Activity/add', form).then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.$router.push('/activity_list')
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
        })
        this.btn = true
      }
      // this.btn = false
    },
    getp() {
      this.$http.get('/admin/Area/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.province = res.data
        }
      })
    },
    mySelect(e) {
      // console.log(e);
      this.form.location = e.point
      this.bus.$emit('pushaddress', e.point)
    },
    getc() {
      console.log(this.form.province)
      if (this.form.province) {
        this.$http
          .get('/admin/Area/getList?code=' + this.form.province)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              console.log(this.form.province)
              console.log(res)
              this.city = res.data
            }
          })
      }
    },
    getqu() {
      if (this.form.city.length != 0) {
        this.$http
          .get('/admin/Area/getList?code=' + this.form.city)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.district = res.data
            }
          })
      }
    },
    ci() {
      this.getc()
      this.form.city = ''
      this.form.district = ''
    },
    cs() {
      this.bus.$emit('getmap', this.form.city)
      this.$forceUpdate()
      this.getqu()
      this.form.district = ''
    },
    addedit() {
      const form = {
        ...this.form,
        regUrl: this.form.regUrl,
        otherUrl: this.form.otherUrl,
        beginTime: this.form.time[0],
        endTime: this.form.time[1],
        location: !!this.form.location.lat
          ? this.form.location.lat + ',' + this.form.location.lng
          : '',
        applyBeginTime: this.form.timeSigningUp && this.form.timeSigningUp[0],
        applyEndTime: this.form.timeSigningUp && this.form.timeSigningUp[1],
        ticketJson: JSON.stringify(this.ticketList),
        forms: JSON.stringify(this.$refs.newForm.configFormData.list),
        enterpriseIds: this.form.enterpriseIds.toString(),
        speakerIds: this.form.speakerIds.toString()
      }

      this.$refs.formref.validate(() => {
        if (this.channel == 2 && !this.form.regUrl) {
          this.$message.error('请输入活动行报名链接')

          return
        }
        this.$http.post('/admin/Activity/edit', form).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$router.push('/activity_list')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  },
  components: {
    Map,
    // Tiny: tiny,
    upImg,
    newForm: () => import('@/components/newForm/container.vue'),
    Drawers: () => import('@/components/Drawer')
  },
  computed: {
    lasturl() {
      if (this.form.isOld) {
        return '/yfchuhai.com/dedecms'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.left {
  border-left: none;
}
.map_if {
  display: none;
}
.in {
  // -webkit-appearance: none;
  // background-color: #fff;
  // background-image: none;
  // border-radius: 4px;
  // border: 1px solid #dcdfe6;
  // box-sizing: border-box;
  // color: #606266;
  // display: inline-block;
  // font-size: inherit;
  // height: 40px;
  // line-height: 40px;
  margin-left: 30px;
  // padding-left: 30px;
  // outline: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: auto;
  height: 178px;
  display: block;
}
.biaodan {
  margin-top: 50px;
  .left {
    float: left;
    width: 60%;
    span {
      display: inline-block;
      width: 80px;
      margin-right: 4px;
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    .el-tag {
      width: 97px;
      margin-right: 5px;
      text-align: center;
    }
  }
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
<style>
.el-message.el-message--error {
  z-index: 20000 !important;
}
.el-select-dropdown.el-popper {
  z-index: 20000 !important;
}
</style>
